import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './index.css'
import Mapbox from '../mapbox'
import TumbonAddr from '../util/TumbonAddr'
import Calculate from '../util/calculate';
import listProvinceConst from '../constant/province'
import api from '../util/api';

import Layout from '../components/Layout';
import { Checkbox, Col, Input, Radio, Row, Select, Table, Button, Space, Tooltip, Modal } from 'antd';
import IconButton from '@mui/material/IconButton';
import _ from 'lodash';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official';
import HC_more from "highcharts/highcharts-more";
import highchartsDumbbell from "highcharts/modules/dumbbell";
import highchartsLollipop from "highcharts/modules/lollipop";
import { AreaChartOutlined, BarChartOutlined } from '@ant-design/icons';
import RemoveIcon from '@mui/icons-material/Remove';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import RefreshIcon from '@mui/icons-material/Refresh';

if (typeof Highcharts === 'object') {
  HC_more(Highcharts);
  highchartsDumbbell(Highcharts);
  highchartsLollipop(Highcharts);
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

const yearSemesterList = [
  { text: `1/2567`, value: `25671` },
  { text: `2/2566`, value: `25662` },
  { text: `1/2566`, value: `25661` },
  { text: `2/2565`, value: `25652` },
  { text: `1/2565`, value: `25651` },
  { text: `2/2564`, value: `25642` },
  { text: `1/2564`, value: `25641` },
  { text: `2/2563`, value: `25632` },
  { text: `1/2563`, value: `25631` },
  { text: `2/2562`, value: `25622` },
  { text: `1/2562`, value: `25621` },
  { text: `2/2561`, value: `25612` },
]

const EEF_MATRIX = {
  exjon: { key: 'exjon', text: 'ทุนเสมอภาค', color: "#ef946c" },
  // covid: { text: 'COVID19-CCT', color: "#06b6d4" },
  // covid_ecd: { text: 'COVID19-ECD', color: "#74d7b8" },
  // covid_oosc: { text: 'COVID19-OOSC', color: "#74d7b8" },
  // fund: { text: 'เงินบริจาค', color: "#22d3ee" },
  krt: { key: 'krt', text: 'ครูรัก(ษ์)ถิ่น', color: "#4ade80" },
  vec: { key: 'vec', text: 'นวัตกรรมสายอาชีพ', color: "#15803d" },
  tps: { key: 'tps', text: 'ทุนพระกนิษฐา', color: "#7c3aed" },
  target_group: { key: 'target_group', text: 'ทุนพัฒนาอาชีพและนวัตกรรมที่ใช้ชุมชนเป็นฐาน', color: "#4338ca" },
  abe_ecd: { key: 'abe_ecd', text: 'เด็กปฐมวัย', color: "#f472b6" },
  abe_oosc: { key: 'abe_oosc', text: 'เด็กที่ไม่มีข้อมูลในระบบการศึกษา (แผนงาน 20 จังหวัด)', color: "#c084fc" },
}

const listProvince = listProvinceConst.addr
  .map(p => ({ province_name: p?._id?.province_name }))
  .sort((a, b) => {
    if (a.province_name < b.province_name) { return -1; }
    if (b.province_name > a.province_name) { return 1; }
    return 0;
  });
// const listProvince = []

const PageDashboard = (props) => {
  // console.log('listProvince',listProvince);

  const [loading, setLoading] = useState(true)
  const [data, setData] = useState({})

  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(true);

  // const [studentReceiveByProvince, setStudentReceiveByProvince] = useState([])
  const [filterStudentReceiveByProvince, setFilterStudentReceiveByProvince] = useState({
    sortType: 'descending',
  })

  const [searchProvince, setSearchProvince] = useState('')
  const [selectedTypeHelpFromCCT, setSelectedTypeHelpFromCCT] = useState('จำนวนคน')
  const [selectedTypeHelpByFund, setSelectedTypeHelpByFund] = useState('จำนวนคน')

  const [filter, setFilter] = useState({
    view: 'country',
    year: '2567',
    semester: "1",
    educlass: "ทั้งหมด",
    jontype: "total_student",
    tuntype: ['exjon', 'krt', 'vec', 'tps', 'target_group', 'abe_oosc', 'abe_ecd'],
    selected_yearsemester: yearSemesterList[0],
    selected_province: listProvince.map(({ province_name }) => province_name),
  })

  const [dataChart, setDataChart] = useState({ all_year: {} })
  const [dataTable, setDataTable] = useState({ colDef: [], data: [] })
  const [modalfilter, setModalFilter] = useState(false);

  const [windowDimensions, setWindowDimensions] = useState({ width: 0, height: 0 });

  const _mapChartData = () => {
    const checkBarZero = (value) => {
      if (value === 0) return 1;

      return value
    }
    let all_year = {
      chart: {
        type: windowDimensions.width <= 1280 ? 'bar' : 'column'
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: yearSemesterList.map(ys => (ys.text))
      },
      yAxis: {
        title: {
          text: `${selectedTypeHelpFromCCT}`
        },
        min: 0,
        type: "logarithmic",
        custom: {
          allowNegativeLog: true
        }
      },
      tooltip: {
        formatter: function () {
          if (this.point.stackTotal === 0 || this.point.stackTotal === 1) return `
          <b>${this?.x}</b>
          <br/>
          ${this?.series?.userOptions?.stack}: <b>${Highcharts.numberFormat(0, 0)}</b> ${selectedTypeHelpFromCCT === "จำนวนเงิน" ? 'บาท' : 'คน'}
        `
          return `
              <b>${this?.x}</b>
              <br/>
              ${this?.series?.userOptions?.stack}: <b>${Highcharts.numberFormat(this.point.stackTotal, 0)}</b> ${selectedTypeHelpFromCCT === "จำนวนเงิน" ? 'บาท' : 'คน'}
            `
        }
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: true,
            allowOverlap: true,
            rotation: 270,
            y: -20,
            formatter: function () {
              if (this.y !== 0 && this.y !== 1) {
                return `${this.y.toLocaleString('en-Us')} คน`
              }
            }
          }
        },
        series: {
          borderRadius: 3,
          pointWidth: 15,
          // maxPointWidth: 50,
          pointPadding: windowDimensions.width <= 1280 ? 0 : -20,
          dataLabels: {
            formatter: function () {
              return `${this.y.toLocaleString('en-Us')}`
            },
            enabled: false,
            shadow: false,
            style: {
              fontSize: "0.8rem",
              textShadow: "1px",
            }
          },
        }
      },
      series: Object.values(EEF_MATRIX).map(item => {
        let serie = {
          name: item.text,
          data: yearSemesterList.map(ys => (
            checkBarZero(
              _.sumBy((data[`data${ys.value}`] || []).filter(p => filter.selected_province.includes(p.province_name)),
                `${selectedTypeHelpFromCCT === "จำนวนเงิน" ? 'money_' : ''}${item.key}`)
            )
          )),
          visible: filter.tuntype.includes(item.key),
          color: EEF_MATRIX[item.key].color,
          stack: item.text
        }
        return serie
      })
    }
    let countHelpFromCCTAll = data[`data${filter.selected_yearsemester?.value}`]
      ?.filter(prov => filter.selected_province.includes(prov.province_name))
      ?.reduce((prev, curr) => {
        return prev
          + (filter.tuntype.includes('exjon') ? curr['exjon'] : 0)
          // + curr['covid']
          // + curr['covid_ecd']
          // + curr['covid_oosc']
          // + curr['fund']
          + (filter.tuntype.includes('krt') ? curr['krt'] : 0)
          + (filter.tuntype.includes('vec') ? curr['vec'] : 0)
          + (filter.tuntype.includes('tps') ? curr['tps'] : 0)
          + (filter.tuntype.includes('target_group') ? curr['target_group'] : 0)
          + (filter.tuntype.includes('abe_ecd') ? curr['abe_ecd'] : 0)
          + (filter.tuntype.includes('abe_oosc') ? curr['abe_oosc'] : 0)
      }, 0)
    let countMoneyHelpFromCCTAll = data[`data${filter.selected_yearsemester?.value}`]
      ?.filter(prov => filter.selected_province.includes(prov.province_name))
      ?.reduce((prev, curr) => {
        return prev
          + (filter.tuntype.includes('exjon') ? curr['money_exjon'] : 0)
          // + curr['money_covid']
          // + curr['money_covid_ecd']
          // + curr['money_covid_oosc']
          // + curr['money_fund']
          + (filter.tuntype.includes('krt') ? curr['money_krt'] : 0)
          + (filter.tuntype.includes('vec') ? curr['money_vec'] : 0)
          + (filter.tuntype.includes('tps') ? curr['money_tps'] : 0)
          + (filter.tuntype.includes('target_group') ? curr['money_target_group'] : 0)
          + (filter.tuntype.includes('abe_ecd') ? curr['money_abe_ecd'] : 0)
          + (filter.tuntype.includes('abe_oosc') ? curr['money_abe_oosc'] : 0)
      }, 0)
    let countMultiHelpFromCCTAll = data[`data${filter.selected_yearsemester?.value}`]
      ?.filter(prov => filter.selected_province.includes(prov.province_name))
      ?.reduce((prev, curr) => {
        return prev
        // + curr['exjon_covid']
        // + curr['exjon_fund']
        // + curr['covid_fund']
      }, 0)
    let year_tun = {
      chart: {
        type: windowDimensions.width <= 1280 ? 'column' : 'bar'
      },
      title: {
        text: ""
      },
      subtitle: {
        enabled: false
      },

      xAxis: {
        categories:
          Object
            .keys(EEF_MATRIX)
            .filter(propName => {
              const countPropData = _.sumBy(
                data[`data${filter.selected_yearsemester.value}`],
                `${selectedTypeHelpByFund === "จำนวนคน" ? propName : `money_${propName}`}`
              )
              if (countPropData > 0) return true;
              return false;
            })
            .map(propName => EEF_MATRIX[propName].text)
        ,
        title: {
          text: null
        },
      },
      yAxis: {
        min: 0,
        title: {
          align: 'high'
        },
        labels: {
          overflow: 'justify'
        },
        startOnTick: false,
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        lineColor: 'black',
        type: "logarithmic",
        custom: {
          allowNegativeLog: true
        }
      },
      tooltip: {
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true
          }
        },
        series: {
          // pointWidth: 18,
          borderRadius: 5,
          dataLabels: {
            // formatter: function () {
            //   if (this.y != 0) {
            //     return this.y.toLocaleString('en-us') + (option.chart1_display == "count" ? ' คน' : ' บาท')
            //   }
            // },
            enabled: true,
            shadow: false,
            style: {
              fontSize: "10px",
              textShadow: "1px",
            }
          }
        }
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false
      },
      series: [
        {
          name: selectedTypeHelpByFund,
          data:
            Object
              .keys(EEF_MATRIX)
              .filter(propName => {
                const countPropData = _.sumBy(
                  data[`data${filter.selected_yearsemester.value}`]?.filter(p => filter.selected_province.includes(p.province_name)),
                  `${selectedTypeHelpByFund === "จำนวนคน" ? propName : `money_${propName}`}`
                )
                if (countPropData > 0) return true;
                return false;
              })
              .map(propName => {
                const countPropData = _.sumBy(
                  data[`data${filter.selected_yearsemester.value}`]?.filter(p => filter.selected_province.includes(p.province_name)),
                  `${selectedTypeHelpByFund === "จำนวนคน" ? propName : `money_${propName}`}`
                )
                return ({
                  name: EEF_MATRIX[propName]?.text,
                  visible: filter.tuntype.includes(propName),
                  y: countPropData,
                  color: EEF_MATRIX[propName]?.color,
                })
              })
        }
      ]
    }
    let totalHelp = listProvince.filter(p => filter.selected_province.includes(p.province_name)).map(prov => {
      return {
        name: prov?.province_name,
        y: (data[`data${filter.selected_yearsemester?.value}`] || [])
          .filter(p => p.province_name === prov?.province_name)
          .reduce((prev, curr) => {
            return (
              prev
              + (filter.tuntype.includes('exjon') ? curr['exjon'] : 0)
              // + curr['covid']
              // + curr['covid_ecd']
              // + curr['covid_oosc']
              // + curr['fund']
              + (filter.tuntype.includes('krt') ? curr['krt'] : 0)
              + (filter.tuntype.includes('vec') ? curr['vec'] : 0)
              + (filter.tuntype.includes('tps') ? curr['tps'] : 0)
              + (filter.tuntype.includes('target_group') ? curr['target_group'] : 0)
              + (filter.tuntype.includes('abe_ecd') ? curr['abe_ecd'] : 0)
              + (filter.tuntype.includes('abe_oosc') ? curr['abe_oosc'] : 0)
            )
          }, 0)
      }
    })
    let lollipop_tun = {
      chart: {
        type: 'lollipop'
      },

      title: {
        text: '',
      },
      legend: {
        enabled: true,
      },
      credits: {
        enabled: false
      },

      tooltip: {
        shared: true,
        // formatter: function () {
        //   return `
        //       <b>${this?.key}</b>
        //       <br/>
        //       ${this?.series?.name}: <b>${Highcharts.numberFormat(this.y, 0)}</b> คน
        //     `
        // }
      },

      xAxis: {
        type: 'category',
        crosshair: true,
        labels: {
          rotation: -90,
        }
      },

      yAxis: {
        title: {
          text: 'จำนวนคน'
        },
        type: "logarithmic",
        custom: {
          allowNegativeLog: true
        },
        startOnTick: false,
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        plotLines: [{
          color: '#51be9e',
          value: Math.floor(_.minBy(totalHelp, 'y')?.y || 0),
          width: '1',
          zIndex: 10,
          dashStyle: 'longdashdot',
          label: {
            text: `Min ( ${Highcharts.numberFormat(Math.floor(_.minBy(totalHelp, 'y')?.y || 0), 0)} คน )`,
            align: 'right',
            style: {
              fontSize: '14px',
              // fontWeight: 'bold'
            },
            x: -10
          }
        }, {
          color: '#51be9e',
          value: Math.floor(_.meanBy(totalHelp, 'y')),
          width: '1',
          zIndex: 10,
          dashStyle: 'longdashdot',
          label: {
            text: `Average ( ${Highcharts.numberFormat(Math.floor(_.meanBy(totalHelp, 'y')), 0)} คน )`,
            align: 'right',
            style: {
              fontSize: '14px',
              // fontWeight: 'bold'
            },
            x: -10
          }
        }, {
          color: '#51be9e',
          value: Math.floor(_.maxBy(totalHelp, 'y')?.y || 0),
          width: '1',
          zIndex: 10,
          dashStyle: 'longdashdot',
          label: {
            text: `Max ( ${Highcharts.numberFormat(Math.floor(_.maxBy(totalHelp, 'y')?.y || 0), 0)} คน )`,
            align: 'right',
            style: {
              fontSize: '14px',
              // fontWeight: 'bold'
            },
            x: -10
          }
        }]
      },
      plotOptions: {
        lollipop: {
          connectorColor: '#e6e6e6',
          connectorWidth: 1.2,
          borderColor: 'red'
        },
        series: {
          stacking: 'normal',
        }
      },
      series: _mappingDataLollipop()
    }
    return { all_year, countHelpFromCCTAll, countMoneyHelpFromCCTAll, countMultiHelpFromCCTAll, year_tun, lollipop_tun, totalHelp }
  }

  const _mappingDataLollipop = () => {
    const { sortType } = filterStudentReceiveByProvince
    let valMain = filter.tuntype.map(item => {
      let serie = {
        name: `จำนวนผู้ได้รับทุน ${EEF_MATRIX[item].text}`,
        data: listProvince.filter(p => filter.selected_province.includes(p.province_name)).map(prov => {
          return {
            name: prov?.province_name,
            y: (data[`data${filter.selected_yearsemester?.value}`] || [])
              .filter(p => p.province_name === prov?.province_name)
              .reduce((prev, curr) => {
                return (
                  prev + curr[item]
                )
              }, 0),
            sortby: (data[`data${filter.selected_yearsemester?.value}`] || [])
              .filter(p => p.province_name === prov?.province_name)
              .reduce((prev, curr) => {
                return (
                  prev
                  + (filter.tuntype.includes('exjon') ? curr['exjon'] : 0)
                  // + curr['covid']
                  // + curr['covid_ecd']
                  // + curr['covid_oosc']
                  // + curr['fund']
                  + (filter.tuntype.includes('krt') ? curr['krt'] : 0)
                  + (filter.tuntype.includes('vec') ? curr['vec'] : 0)
                  + (filter.tuntype.includes('tps') ? curr['tps'] : 0)
                  + (filter.tuntype.includes('target_group') ? curr['target_group'] : 0)
                  + (filter.tuntype.includes('abe_ecd') ? curr['abe_ecd'] : 0)
                  + (filter.tuntype.includes('abe_oosc') ? curr['abe_oosc'] : 0)
                )
              }, 0)
          }
        }),
        visible: filter.tuntype.includes(item),
        color: EEF_MATRIX[item].color,
      }
      if (sortType === 'descending') serie.data = serie.data.sort((a, b) => b.sortby - a.sortby)
      if (sortType === 'ascending') serie.data = serie.data.sort((a, b) => a.sortby - b.sortby)
      if (sortType === 'provincename') serie.data = serie.data.sort((a, b) => {
        if (a.name < b.name) { return -1; }
        if (b.name > a.name) { return 1; }
        return 0;
      })
      return serie
    })
    return valMain
  }

  useEffect(() => {
    setWindowDimensions(getWindowDimensions());
  }, [])

  useEffect(() => {
    Highcharts.setOptions({
      chart: {

        style: {
          fontFamily: 'Kanit'
        }
      },
      exporting: {
        chartOptions: {
          chart: {
            events: {
              load: function () {
                this.renderer.image('https://obec.blob.core.windows.net/cdn/images/iseelogo1.png', 570, 5, 25, 25)
                  .add();
                this.renderer.image('https://obec.blob.core.windows.net/cdn/images/eef_watermark.png', 160, 70, 332, 285)
                  .add();
                this.renderer.image('https://obec.blob.core.windows.net/cdn/images/eeflogo.png', 535, 5, 30, 25)
                  .add();
              }
            }
          }
        }
      },
      credits: {
        enabled: false
      },
      lang: {
        thousandsSep: ','
      },
      yAxis: {
        labels: {
          formatter: function () {
            if (this.value >= 1000000) {
              return Highcharts.numberFormat(this.value / 1000000, 1) + " ล้าน"
            }
            else if (this.value >= 100000) {
              return Highcharts.numberFormat(this.value / 1000000, 1) + " ล้าน";
            }
            // else if (this.value >= 10000) {
            //   return Highcharts.numberFormat(this.value / 10000, 1) + " หมื่น";
            // }
            // else if (this.value >= 1000) {
            //   return Highcharts.numberFormat(this.value / 1000, 1) + " พัน";
            // }
            else {
              return Highcharts.numberFormat(this.value, 0)
            }
          }
        }
      },
      lang: {
        thousandsSep: ','
      },
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500,
            maxHeight: 400
          },
          chartOptions: {
            legend: {
              align: 'center',
              verticalAlign: 'bottom',
              layout: 'horizontal'
            },
            yAxis: {
              labels: {
                align: 'left',
                x: 0,
                y: -5
              },
              title: {
                text: null
              }
            },
            subtitle: {
              text: null
            },
            credits: {
              enabled: false
            }
          }
        }]
      }
    });
    (function (H) {
      H.addEvent(H.Axis, 'afterInit', function () {
        const logarithmic = this.logarithmic;

        if (logarithmic && this.options.custom.allowNegativeLog) {

          // Avoid errors on negative numbers on a log axis
          this.positiveValuesOnly = false;

          // Override the converter functions
          logarithmic.log2lin = num => {
            const isNegative = num < 0;

            let adjustedNum = Math.abs(num);

            if (adjustedNum < 10) {
              adjustedNum += (10 - adjustedNum) / 10;
            }
            // const result = Math.log(adjustedNum) / Math.LN10;
            const result = (adjustedNum < 10 ? 0 : Math.log(adjustedNum) / Math.LN10);
            return isNegative ? -result : result;
          };

          logarithmic.lin2log = num => {
            const isNegative = num < 0;

            let result = Math.pow(10, Math.abs(num));
            if (result < 10) {
              result = (10 * (result - 1)) / (10 - 1);
            }
            return isNegative ? -result : result;
          };
        }
      });
    }(Highcharts));
  }, [])

  useEffect(async () => {
    let [
      res25612,
      res25621,
      res25622,
      res25631,
      res25632,
      res25641,
      res25642,
      res25651,
      res25652,
      res25661,
      res25662,
      res25671,
    ] = await Promise.all([
      api.getInitData(`/call-eef-dashboard-2561-2`),
      api.getInitData(`/call-eef-dashboard-2562-1`),
      api.getInitData(`/call-eef-dashboard-2562-2`),
      api.getInitData(`/call-eef-dashboard-2563-1`),
      api.getInitData(`/call-eef-dashboard-2563-2`),
      api.getInitData(`/call-eef-dashboard-2564-1`),
      api.getInitData(`/call-eef-dashboard-2564-2`),
      api.getInitData(`/call-eef-dashboard-2565-1`),
      api.getInitData(`/call-eef-dashboard-2565-2`),
      api.getInitData(`/call-eef-dashboard-2566-1`),
      api.getInitData(`/call-eef-dashboard-2566-2`),
      api.getInitData(`/call-eef-dashboard-2567-1`),
    ])
    setData({
      data25612: res25612.data,
      data25621: res25621.data,
      data25622: res25622.data,
      data25631: res25631.data,
      data25632: res25632.data,
      data25641: res25641.data,
      data25642: res25642.data,
      data25651: res25651.data,
      data25652: res25652.data,
      data25661: res25661.data,
      data25662: res25662.data,
      data25671: res25671.data,
    })
    console.log('res25671.data' , [...new Set(res25671.data.map((item) => item.province_name  ))] );
    setLoading(false)

  }, [])

  useEffect(() => {
    if (loading) return;
    let _groupProvince = {}
    let dataContext = data['data' + filter.year + filter.semester]
    dataContext.map(item => {
      if (filter.selected_province.includes(item.province_name)) {
        if (_groupProvince[item.province_name]) {
          Object.values(EEF_MATRIX).map(tun => {
            if (filter.tuntype.includes(tun.key)) _groupProvince[item.province_name][tun.key] += item[tun.key]
          })
        } else {
          _groupProvince[item.province_name] = {}
          _groupProvince[item.province_name]['province_name'] = item['province_name']
          Object.values(EEF_MATRIX).map(tun => {
            if (filter.tuntype.includes(tun.key)) _groupProvince[item.province_name][tun.key] = item[tun.key]
          })
        }
      }
    })
    let colDef = []
    colDef.push({
      title: 'จังหวัด',
      dataIndex: 'province_name',
      key: 'province_name',
      align: 'center',
      render(text, record) {
        return {
          props: {
            // style: { background: `hsl(118, 40%, ${50 - (30 * (text / record.sum)) + 50}%)`, textAlign: "right" }
            style: { textAlign: "center" }
          },
          children: <div>{text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
        };
      },
      sorter: (a, b) => {
        if (a.province_name > b.province_name) {
          return -1;
        }
        if (b.province_name > a.province_name) {
          return 1;
        }
        return 0;
      }
    })
    Object.values(EEF_MATRIX).map(item => {
      if ([...Object.values(_groupProvince)].reduce((acc, cur) => acc + cur[item.key], 0) !== 0 && filter.tuntype.includes(item.key)) {
        colDef.push({
          title: item.text,
          dataIndex: item.key,
          key: item.key,
          align: 'center',
          render(text, record) {
            return {
              props: {
                // style: { background: `hsl(118, 40%, ${50 - (30 * (text / record.sum)) + 50}%)`, textAlign: "right" }
                style: { textAlign: "center" }
              },
              // children: <div>{record[item.key] > 0 ? <CheckIcon color="success" /> : <></>}</div>
              children: <div>{record[item.key] > 0 ? record[item.key].toLocaleString("en-US") : <RemoveIcon color="#000" />}</div>
            };
          },
          sorter: (a, b) => a[item.key] - b[item.key],
        })
      }
    })
    setDataTable({ ...dataTable, colDef: colDef, data: [...Object.values(_groupProvince)] })
    setDataChart(_mapChartData())
  }, [filter, loading, filterStudentReceiveByProvince])


  const onCheckAllChange = (e) => {
    setFilter({
      ...filter,
      selected_province: e.target.checked ?
        listProvince
          .filter(({ province_name }) => {
            if (searchProvince.length === 0) return true;

            return province_name.includes(searchProvince)
          })
          .map(({ province_name }) => (province_name))
        : []
    });
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const Filter = () => {
    return <div className='container-left-nav p-5 flex flex-wrap xl:flex-nowrap xl:flex-col gap-5 bg-white' >
      <div className='flex justify-between'>
        <div className='flex flex-row my-auto'>
          <FilterAltIcon sx={{ color: '#525252' }} />
          <div className='m-auto'>Filter</div>
        </div>
        <div className='flex flex-row my-auto'>
          <Button onClick={() => {
            setSearchProvince('')
            setFilter({
              view: 'country',
              year: '2567',
              semester: "1",
              educlass: "ทั้งหมด",
              jontype: "total_student",
              tuntype: ['exjon', 'krt', 'vec', 'tps', 'target_group', 'abe_oosc', 'abe_ecd'],
              selected_yearsemester: yearSemesterList[0],
              selected_province: listProvince.map(({ province_name }) => province_name),
            })
            setIndeterminate(false);
            setCheckAll(true);
          }} icon={<RefreshIcon sx={{ color: '#525252' }} />}>คืนค่าเริ่มต้น Filter</Button>
        </div>
      </div>
      <div>
        <div>ปีการศึกษา</div>
        <div>
          <Select className='w-full'
            value={filter.selected_yearsemester?.value}
            defaultValue={filter.selected_yearsemester?.value}
            onChange={(e, elem) => {
              setFilter(prev => ({
                ...prev,
                year: elem.value?.substr(0, 4),
                semester: elem.value?.substr(4, 1),
                selected_yearsemester: { value: elem.value, text: elem.text }
              }))
            }}
          >
            {yearSemesterList.map(ys => (
              <Select.Option value={ys?.value} text={ys?.text} >{`ปีการศึกษา ${ys?.text}`}</Select.Option>
            ))}
          </Select>
        </div>
      </div>
      <div className='w-full'>
        <div>ประเภททุน</div>
        <div>
          <Select className='w-full'
            mode="multiple"
            style={{ width: '100%' }}
            allowClear
            value={filter.tuntype}
            defaultValue={filter.tuntype}
            onChange={(e, elem) => {
              setFilter(prev => ({
                ...prev,
                tuntype: elem.map(item => item.value)
              }))
            }}
          >
            {Object.values(EEF_MATRIX).map(item => (
              <Select.Option key={item.key} value={item?.key} text={item?.text} >{`${item?.text}`}</Select.Option>
            ))}
          </Select>
        </div>
      </div>
      <div>
        <div>เลือกจังหวัดที่จะแสดง</div>
        <div>
          <Input placeholder='ค้นหาจังหวัด' allowClear onChange={(e) => setSearchProvince(e?.target?.value)} />
        </div>
        <div className='mt-1 flex flex-col' >
          <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
            เลือกทั้งหมด
          </Checkbox>
          <Checkbox.Group value={filter.selected_province} onChange={(list) => {
            setFilter({
              ...filter,
              selected_province: list
            });
            setIndeterminate(!!list.length && list.length < listProvince.length);
            setCheckAll(list.length === listProvince.length);
          }}>
            {
              listProvince
                .filter(({ province_name }) => {
                  if (searchProvince.length === 0) return true;

                  return province_name.includes(searchProvince)
                })
                .map(({ province_name }) => (
                  <div key={province_name} >
                    <Checkbox value={province_name}>{province_name}</Checkbox>
                  </div>
                ))
            }
          </Checkbox.Group>
        </div>
      </div>
    </div>
  }

  if (loading) {
    return (<Layout Role={['eef']} isHideFooter>
      <div className='relative overflow-hidden'>
        <div className="w-[100vw] h-[100vh] flex flex-col justify-center items-center">
          <div className="text-center" role="status">
            <svg className="inline mr-2 w-32 h-32 text-gray-200 animate-spin dark:text-gray-200 fill-[#038967]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor" />
              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill" />
            </svg>
            <span className="sr-only">Loading...</span>
          </div>
          <div className="text-2xl text-[#038967] font-bold text-center mt-4">  Loading ...</div>
        </div>
      </div>
    </Layout>)
  }
  return (
    // <></>
    <Layout Role={['eef']} isHideFooter>
      <div className='pt-[100px] pb-[30px] px-5' >
        <div className='grid grid-cols-12'>
          <div className='col-span-12 xl:col-span-3'>
            <div className='hidden xl:block xl:fixed overflow-y-auto max-h-[20vh] xl:max-h-[87vh] w-full mb-4 xl:w-[23%]' >
              <Filter />
            </div>
          </div>
          <div className='col-span-12 xl:col-span-9'>
            <div className='w-full'>
              <div className='card-container' >
                <div className='card-header' >
                  <BarChartOutlined className='card-header-icon' />
                  <span className='card-header-title text-base xl:text-2xl' >
                    นักเรียนที่ได้รับการช่วยเหลือจาก กสศ. ปีการศึกษา 2/2561 - 1/2567
                  </span>
                </div>
                <div className='card-body' >
                  <HighchartsReact
                    containerProps={{ style: { height: windowDimensions.width <= 1280 ? '100vh' : '100%' } }}
                    highcharts={Highcharts}
                    options={dataChart.all_year}
                  />
                </div>
              </div>
            </div>
            <div className='grid grid-cols-1 xl:grid-cols-3 gap-4 mt-6'>
              <div className='col-span-1'>
                <div className='card-header-top bg-midnight-bloom mb-4'>
                  <div className='text-white text-sm xl:text-base'>
                    จำนวนผู้ได้รับทุนจาก กสศ.
                  </div>
                  <div className="text-[#3ac47d] text-xl xl:text-[2.5rem] xl:py-4">
                    {Highcharts.numberFormat(dataChart.countHelpFromCCTAll, 0)} คน
                  </div>
                </div>
              </div>
              <div className='col-span-1'>
                <div className='card-header-top bg-asteroid mb-4'>
                  <div className='text-white text-sm xl:text-base'>
                    จำนวนเงินที่ กสศ.ให้การช่วยเหลือ
                  </div>
                  <div className="text-[#3ac47d] text-xl xl:text-[2.5rem] xl:py-4">
                    {Highcharts.numberFormat(dataChart.countMoneyHelpFromCCTAll, 0)} บาท
                  </div>
                </div>
              </div>
              <div className='col-span-1'>
                <div className='card-header-top bg-premium-dark mb-4'>
                  <div className='text-white text-sm xl:text-base'>
                    ผู้ได้รับทุนจาก กสศ.หลายทุน
                  </div>
                  <div className="text-[#3ac47d] text-xl xl:text-[2.5rem] xl:py-4">
                    {Highcharts.numberFormat(dataChart.countMultiHelpFromCCTAll, 0)} คน
                  </div>
                </div>
              </div>
            </div>
            <div className='w-full mt-6'>
              <div className='card-container' >
                <div className='card-header' >
                  <AreaChartOutlined className='card-header-icon' />
                  <span className='card-header-title text-base xl:text-2xl' >
                    สรุปนักเรียนที่ได้รับการช่วยเหลือจาก กสศ.
                  </span>
                  {/*
                      <div className='ml-auto' >
                        <Radio.Group
                          options={[
                            { label: 'จำนวนคน', value: 'จำนวนคน' },
                            { label: 'จำนวนเงิน', value: 'จำนวนเงิน' },
                            // 'จำนวนคน',
                            // 'จำนวนเงิน'
                          ]}
                          onChange={e => setSelectedTypeHelpByFund(e.target.value)}
                          value={selectedTypeHelpByFund}
                          optionType="button"
                          buttonStyle="solid"
                        />
                      </div> 
                    */}
                </div>
                <div className='card-body h-[565px]'>
                  <HighchartsReact
                    containerProps={{ style: { height: "100%" } }}
                    highcharts={Highcharts}
                    options={dataChart.year_tun}
                  />
                </div>
              </div>
            </div>
            <div className='w-full mt-5'>
              <div className='card-container' >
                <div className='card-header' >
                  <AreaChartOutlined className='card-header-icon' />
                  <span className='card-header-title text-base xl:text-2xl' >
                    จำนวนนักเรียนที่ได้รับการช่วยเหลือจำแนกตามจังหวัด
                  </span>
                </div>
                <div className='card-body'>
                  <div className='grid grid-cols-1 xl:grid-cols-3 gap-4 mb-6'>
                    <div className='col-span-1'>
                      <div className='card-header-top flex bg-midnight-bloom gap-[10px] items-center min-h-[80px]'>
                        <div className='text-white '>
                          <div>จังหวัดที่ กสศ. ช่วยเหลือมากที่สุด</div>
                          <div className='opacity-60' >{_.maxBy(dataChart.totalHelp, 'y')?.name || ''}</div>
                        </div>
                        <div className="text-[#f7b924] text-[1.5rem] ml-auto font-black">
                          {Highcharts.numberFormat(Math.floor(_.maxBy(dataChart.totalHelp, 'y')?.y || 0), 0)} คน
                        </div>
                      </div>
                    </div>
                    <div className='col-span-1'>
                      <div className='card-header-top flex bg-premium-dark gap-[10px] items-center min-h-[80px]'>
                        <div className='text-white '>
                          <div>การช่วยเหลือเฉลี่ยต่อจังหวัด</div>
                        </div>
                        <div className="text-[#f7b924] text-[1.5rem] ml-auto font-black">
                          {Highcharts.numberFormat(Math.floor(_.meanBy(dataChart.totalHelp, 'y')), 0)} คน
                        </div>
                      </div>
                    </div>
                    <div className='col-span-1'>
                      <div>
                        เรียงลำดับ
                      </div>
                      <div>
                        <Select
                          className='w-full custom-border-select border-[#794c8a] shadow-[0_0_5px_#794c8a]'
                          value={filterStudentReceiveByProvince.sortType}
                          onChange={(e) => setFilterStudentReceiveByProvince(prev => ({ ...prev, sortType: e }))}
                        >
                          <Select.Option value="descending" >เรียงจากมากไปน้อย</Select.Option>
                          <Select.Option value="ascending" >เรียงจากน้อยไปมาก</Select.Option>
                          <Select.Option value="provincename" >เรียงตามชื่อจังหวัด</Select.Option>
                        </Select>
                      </div>
                    </div>
                  </div>
                  <HighchartsReact
                    containerProps={{ style: { height: windowDimensions.width <= 1280 ? '70vh' : '60vh' } }}
                    highcharts={Highcharts}
                    options={dataChart.lollipop_tun}
                  />
                </div>
              </div>
            </div>
            <div className='w-full mt-5'>
              <div className='card-container' >
                <div className='card-header' >
                  <BarChartOutlined className='card-header-icon' />
                  <span className='card-header-title text-base xl:text-2xl' >
                    จังหวัดที่ได้รับการช่วยเหลือผ่านทุน/โครงการ ของปี {filter.semester + '/' + filter.year}
                  </span>
                </div>
                <div className='card-body' >
                  <Table
                    size="small"
                    bordered
                    style={{ width: '100%' }}
                    scroll={{ x: dataTable.colDef.length * 100, y: 500 }}
                    pagination={{ pageSize: 12 }}
                    columns={dataTable.colDef}
                    dataSource={dataTable.data}
                  >
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='block xl:hidden fixed bottom-8 right-8'>
          <div className='w-12 h-12 rounded-2xl bg-blue-900'>
            <IconButton className='m-auto' aria-label="filter" size="large" onClick={() => setModalFilter(true)}>
              <FilterAltIcon className='text-white' />
            </IconButton>
          </div>
        </div>
        <Modal
          width={800}
          visible={modalfilter}
          // title="แหล่งที่มาของข้อมูล"
          onOk={() => setModalFilter(false)}
          onCancel={() => setModalFilter(false)}
          cancelButtonProps={{ style: { display: "none" } }}
        >
          <Filter />
        </Modal>
      </div>
    </Layout >
  )
}

export default PageDashboard
